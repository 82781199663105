import React from "react";
import Aberturas from "../products/preview/Aberturas";

const AberturasDescription = () => {
  const style = {
    objectFit: {
      objectFit: "cover",
    },
    minHeight: {
      minHeight: "400px",
    },
  };

  return (
    <div className="container-fluid py-6 px-5">
      <div className="row g-5">
        <div className="col-lg-5 pb-5" style={style.minHeight}>
          <div className="position-relative bg-dark-radial h-100 ms-5">
            <img
              className="position-absolute w-100 h-100 mt-5 ms-n5"
              src="img/productos/aberturas/aberturas_main.png"
              style={style.objectFit}
            />
          </div>
        </div>
        <div className="col-lg-7">
          <h1 className="display-5 text-uppercase mb-5">
            Aberturas en <span className="text-primary">Aluminio</span>
          </h1>
          <h4 className="text-uppercase mb-3 text-body">
            Fabricación bajo procesos automatizados que aseguran la máxima
            calidad
          </h4>
          <p>
            En urucortinas somos distribuidores oficiales de Lidasur Aluminios,
            empresa líder en el mercado en la fabricación y distribución de
            aberturas en aluminio. Mediante procesos automatizados de ensamble
            se asegura la máxima calidad y garantía de los productos
            comercializados. Además de esto, toda la perfileria utilizada es de
            Aluminios del Uruguay, empresa certificada bajo las mas estrictas
            normas de calidad.
          </p>
          <div className="row gx-5 py-2">
            <div className="col-sm-6 mb-2">
              <p className="fw-bold mb-2">
                <i className="fa fa-check text-primary me-3"></i>Puertas,
                ventanas, monoblocks.
              </p>
              <p className="fw-bold mb-2">
                <i className="fa fa-check text-primary me-3"></i>Medidas
                estándar con entrega inmediata en serie 20, 25 y 30.
              </p>
            </div>
            <div className="col-sm-6 mb-2">
              <p className="fw-bold mb-2">
                <i className="fa fa-check text-primary me-3"></i>Aberturas de
                alta prestación en serie Probba y Gala con DVH
              </p>
              <p className="fw-bold mb-2">
                <i className="fa fa-check text-primary me-3"></i>Entrega en todo
                el país
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AberturasDescription;
