import React from "react";

const Aberturas = () => {
  return (
    <div className="col-lg-4 col-md-6">
      <div className="service-item bg-white d-flex flex-column align-items-center text-center">
        <img
          className="img-fluid"
          src="img/productos/aberturas/abertruras-preview.jpeg"
          alt=""
        />
        <div className="px-4 pb-4">
          <h4 className="text-uppercase mb-3 mt-3">Aberturas en aluminio</h4>
          <p>
            Somos distribuidores de Lidasur Aluminios, empresa líder en el
            mercado en la fabricación y distribución de aberturas en aluminio.
            Perfileria de Aluminios del Uruguay
          </p>
          <a className="btn text-primary" href="productos/aberturas">
            Mas información <i className="bi bi-arrow-right"></i>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Aberturas;
