import React from "react";
import ServiceHeader from "../components/headers/ServiceHeader";
import Detail from "../components/services/detail/Detail";
import { useParams } from "react-router-dom";
import QuotePanel from "../components/QuotePanel";

const ServiceDetail = () => {
  let { name } = useParams();

  return (
    <>
      <ServiceHeader param={name} />
      <Detail param={name} />
      <QuotePanel />
    </>
  );
};

export default ServiceDetail;
