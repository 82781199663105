import React from "react";
import { Routes, Route,Navigate } from 'react-router-dom'
import Home from './pages/Home'
import Services from './pages/Services'
import Layout from './layout/Layout'
import ServiceDetail from "./pages/ServiceDetail";
import Quote from "./pages/Quote";
import Contact from "./pages/Contact";
import Products from "./pages/Products";
import ProductListPanel from "./components/products/ProductListPanel";
import Aberturas from "./pages/Aberturas";
import NotFound from "./pages/NotFound";



function App() {
  return (
    <>
    {/* <Scripts/> */}
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Home />} />
        <Route path="servicios"  element={<Services/>}/>
        <Route path="servicios/:name" element={<ServiceDetail/>} />
        <Route path="solicitar-presupuesto" element={<Quote/>} />
        <Route path="contacto"  element={<Contact/>}/>
        <Route path="productos"  element={<Products/>}/>
        <Route path="productos/todos" element={<ProductListPanel/>} />
        <Route path="productos/:id" element={<Aberturas/>} />
        <Route path="/404" element={<NotFound />} />
        <Route path="*" element={<Navigate to="/404" />} />
      </Route>
    </Routes>
    </>
  );
}

export default App;
