import React from "react";

const Whatsapp = () => {
  const style = {
    zIndex: "9999",
    position: "fixed",
    bottom: "60px",
    right: "50px",
    width: "48px",
  };

  return (
    <>
      <p>
        <a href="https://api.whatsapp.com/send?phone=59897365931&amp;text=Hola,%20te%20contacto%20desde%20la%20web%20de%20urucortinas:">
          <img
            alt="Haznos tu consulta"
            src="img//icons/whatsapp_icon.png"
            style={style}
          />
        </a>
      </p>
    </>
  );
};

export default Whatsapp;
