import React from "react";
import { Helmet } from "react-helmet";

const Sanitaria = () => {
  const style = {
    objectFit: {
      objectFit: "cover",
    },
    minHeight: {
      minHeight: "400px",
    },
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Sanitaria - construcción urucortinas</title>
        <link
          rel="canonical"
          href="https://construccion.urucortinas.com.uy/servicios/sanitaria"
        />
        <meta
          name="description"
          content="Sanitaria - construcción urucortinas"
        />
      </Helmet>
      <div className="container-fluid py-6 px-5">
        <div className="row g-5">
          <div className="col-lg-5 pb-5" style={style.minHeight}>
            <div className="position-relative bg-dark-radial h-100 ms-5">
              <img
                className="position-absolute w-100 h-100 mt-5 ms-n5"
                src="img/servicios/sanitaria/sanitaria_detail.jpeg"
                style={style.objectFit}
              />
            </div>
          </div>
          <div className="col-lg-7">
            <h1 className="display-5 text-uppercase mb-4">
              Servicios de <span className="text-primary">sanitaria</span>
            </h1>
            <h4 className="text-uppercase mb-3 text-body">
              Personal técnico capacitado para solucionar todo tipo de
              desperfectos
            </h4>
            <p>
              Mantenimiento sanitario en general para edificios, complejos,
              casas y oficinas públicas y privadas. Limpieza de graseras y de
              tanques, pasaje de cinta, desobstrucciones. También realizamos
              reemplazo de cañerías por termofusiòn y revestimientos de baños y
              cocinas.
            </p>
            <div className="row gx-5 py-2">
              <div className="col-sm-6 mb-2">
                <p className="fw-bold mb-2">
                  <i className="fa fa-check text-primary me-3"></i>
                  Desobstrucción de cañerias y drenajes
                </p>
                <p className="fw-bold mb-2">
                  <i className="fa fa-check text-primary me-3"></i>Detección de
                  pérdidas
                </p>
                <p className="fw-bold mb-2">
                  <i className="fa fa-check text-primary me-3"></i>Limpieza de
                  graseras
                </p>
              </div>
              <div className="col-sm-6 mb-2">
                <p className="fw-bold mb-2">
                  <i className="fa fa-check text-primary me-3"></i>Cambio de
                  Grifería
                </p>
                <p className="fw-bold mb-2">
                  <i className="fa fa-check text-primary me-3"></i>Reemplazo de
                  cañerias
                </p>
                <p className="fw-bold mb-2">
                  <i className="fa fa-check text-primary me-3"></i>Revestimiento
                  de baños y cocinas
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sanitaria;
