import React from "react";
import { Helmet } from "react-helmet";

const ContenedoresDetail = () => {
  const style = {
    objectFit: {
      objectFit: "cover",
    },
    minHeight: {
      minHeight: "400px",
    },
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Contenedores - construcción urucortinas</title>
        <link
          rel="canonical"
          href="https://construccion.urucortinas.com.uy/servicios/contenedores"
        />
        <meta
          name="description"
          content="Contenedores - - construcción urucortinas | Montevideo | Uruguay | 097365931. Contenedores marítimos prefabricados a medida. Entrega inmediata"
        />
      </Helmet>

      <div className="container-fluid py-4 px-4">
        <div className="row g-5">
          <div className="col-lg-5 pb-5" style={style.minHeight}>
            <div className="position-relative bg-dark-radial h-100 ms-5">
              <img
                className="position-absolute w-100 mt-5 ms-n5"
                src="img/servicios/contenedores/contenedores-preview.jpg"
              />
            </div>
          </div>
          <div className="col-lg-7">
            <h1 className="display-5 text-uppercase mb-4">Contenedores Casa</h1>
            <h4 className="text-uppercase mb-3 text-body">
              Vivienda, oficina, depósito. Sea cual sea el destino, los
              contenedores marítimos se adaptan de manera rápida y efectiva.
            </h4>
            <p>
              En una creciente demanda de vivienda a nivel mundial, las
              soluciones constructivas rápidas y flexibles sobresalen por sobre
              las tradicionales. Los contenedores marítimos proporcionan una
              solución habitacional a medida y en un período muy corto de
              tiempo. La posibilidad de combinar contenedores, creando
              diferentes estructuras lo convierten en una solucion ideal en
              tiempos de constante cambio. Las construcciones a partir de
              contenedores tienen las mismas prestaciones y comodidad que una
              construcción tradicional. En cuanto a la estética exterior, es
              ampliamente adaptable, pudiendo seleccionar entre diferentes
              materiales y acabados .
            </p>
            <div className="row gx-5 py-2">
              <div className="col-sm-6 mb-2">
                <p className="fw-bold mb-2">
                  <i className="fa fa-check text-primary me-3"></i>Módulos
                  equipados y acondicionados a medida
                </p>
                <p className="fw-bold mb-2">
                  <i className="fa fa-check text-primary me-3"></i>Para vivienda
                  u oficina
                </p>
              </div>
              <div className="col-sm-6 mb-2">
                <p className="fw-bold mb-2">
                  <i className="fa fa-check text-primary me-3"></i>Alquiler y
                  venta
                </p>
                <p className="fw-bold mb-2">
                  <i className="fa fa-check text-primary me-3"></i>Entrega en
                  todo el país
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContenedoresDetail;
