import React from "react";

const NotFound = () => {
  return (
    <>
      {/* <div class="container-fluid page-header mb-5 py-5">
        <div class="container">
          <h1 class="display-3 text-white mb-3 animated slideInDown">
            Not Found
          </h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol class="breadcrumb text-uppercase">
              <li class="breadcrumb-item">
                <a class="text-white" href="/">
                  Home
                </a>
              </li>
              <li class="breadcrumb-item text-white active" aria-current="page">
                404
              </li>
            </ol>
          </nav>
        </div>
      </div> */}

      <div class="container-xxl py-5 wow fadeInUp" data-wow-delay="0.1s">
        <div class="container text-center">
          <div class="row justify-content-center">
            <div class="col-lg-6">
              <i class="bi bi-exclamation-triangle display-1 text-primary"></i>
              <h1 class="display-1">404</h1>
              <h1 class="mb-4">Página no encontrada</h1>
              <p class="mb-4">
                La página ingresada no existe en nuestro sitio! Puede volver a
                nuestro menú principal para continuar navegando
              </p>
              <a class="btn btn-primary rounded-pill py-3 px-5" href="/">
                Volver a inicio
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NotFound;
