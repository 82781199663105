import React from "react";

const Contenedores = () => {
  return (
    <div className="col-lg-4 col-md-6">
      <div className="service-item bg-white rounded d-flex flex-column align-items-center text-center">
        <img
          className="img-fluid"
          src="img/servicios/contenedores/container_detail.jpg"
          alt=""
        />
        <div className="service-icon bg-white">
          <i className="fa fa-3x fa-home text-primary"></i>
        </div>
        <div className="px-4 pb-4">
          <h4 className="text-uppercase mb-3">Contenedores Prefabricados</h4>
          <p>
            Solución habitacional a medida y en tiempo récord. Venta y alquiler
            de contenedores con destino vivienda u oficina. Módulos
            completamente equipados con entrega en todo el país. Conozca
            nuestros planes y diseños
          </p>
          <a className="btn text-primary" href="servicios/contenedores">
            Mas información <i className="bi bi-arrow-right"></i>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Contenedores;
