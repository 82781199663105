import React from 'react'
import Logo from './Logo'

const TopBar = () => {
  return (
    <>
         {/* <!-- Topbar Start --> */}
    <div className="container-fluid">
        <div className="row">
        <Logo/>   
            <div className="col-lg-3 text-center py-3  d-none d-lg-block">
                <div className="d-inline-flex align-items-center">
                    <i className="bi bi-geo-alt fs-1 text-primary me-3"></i>
                    <div className="text-start">
                        <h6 className="text-uppercase fw-bold">Trabajamos en todo el País</h6>
                        <span>Consultá presupuesto!</span>
                    </div>
                </div>
            </div>
            <div className="col-lg-3 text-center border-start border-end py-3  d-none d-lg-block">
                <div className="d-inline-flex align-items-center">
                    <i className="bi bi-envelope-open fs-1 text-primary me-3"></i>
                    <div className="text-start">
                        <h6 className="text-uppercase fw-bold">Correo Electrónico</h6>
                        <span>construccion@urucortinas.com.uy</span>
                    </div>
                </div>
            </div>
            <div className="col-lg-3 text-center py-3  d-none d-lg-block">
                <div className="d-inline-flex align-items-center">
                    <i className="bi bi-phone-vibrate fs-1 text-primary me-3"></i>
                    <div className="text-start">
                        <h6 className="text-uppercase fw-bold">Teléfono</h6>
                        <span>097 365 931</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    {/* <!-- Topbar End --> */}
    </>
  )
}

export default TopBar
