import React from 'react'

const SaberMas = ({href}) => {
  return (
    <>
      <a className="btn btn-primary py-3 px-5" href={href}>Saber Mas</a>
    </>
  )
}

export default SaberMas
