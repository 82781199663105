import React from 'react'

const Impermeabilizaciones = () => {
  return (
       <div className="col-lg-4 col-md-6">
                <div className="service-item bg-white rounded d-flex flex-column align-items-center text-center">
                    <img className="img-fluid" src="img/servicios/impermeabilizaciones/impermeabilización-asfáltica.png" alt=""/>
                    <div className="service-icon bg-white">
                        <i className="bi bi-paint-bucket h1 text-primary"></i>
                    </div>
                    <div className="px-4 pb-4">
                        <h4 className="text-uppercase mb-3">Impermeabilizaciones</h4>
                        <p>Tratamiento antihúmedad. Impermeabilización de paredes, azoteas, mantenimiento de terrazas, colocación de membrana asfáltica</p>
                        <a className="btn text-primary" href="servicios/impermeabilizaciones">Mas información <i className="bi bi-arrow-right"></i></a>
                    </div>
                </div>
            </div>
  )
}

export default Impermeabilizaciones
