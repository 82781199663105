import React from "react";
import QuotePanel from "../components/QuotePanel";
import QuoteHeader from "../components/headers/QuoteHeader";
import FaqPanel from "../components/faq/FaqPanel";
import { Helmet } from "react-helmet";

const Quote = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Solicitar Presupuesto</title>
        <link
          rel="canonical"
          href="https://construccion.urucortinas.com.uy/solicitar-presupuesto"
        />
        <meta
          name="description"
          content="Solicitá presupuesto | urucortinas construcción | Montevideo | Uruguay | 097365931 "
        />
      </Helmet>
      <QuoteHeader />
      <QuotePanel />
      <FaqPanel />
    </>
  );
};

export default Quote;
