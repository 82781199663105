import React from "react";

const AberturasItem = ({ title, subTitle, img }) => {
  return (
    <div className="col-lg-4 col-md-6">
      <div className="service-item bg-white d-flex flex-column align-items-center text-center">
        <img className="img-fluid" src={img} alt="" />
        <div className="col-12 text-center ">
          <div className="bg-light p-4 ">
            <h4 className="text-uppercase">{title}</h4>
            <span>{subTitle}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AberturasItem;
