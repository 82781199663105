import React from "react";
import AberturasPanel from "../components/aberturas/AberturasPanel";
import AberturasDescription from "../components/aberturas/AberturasDescription";
import QuotePanel from "../components/QuotePanel";
import { Helmet } from "react-helmet";

const Aberturas = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title> Aberturas - construcción urucortinas</title>
        <link
          rel="canonical"
          href="https://construccion.urucortinas.com.uy/productos/aberturas"
        />
        <meta
          name="description"
          content="Aberturas en aluminio todas las medidas y perfiles. Entrega e instalación en todo el país"
        />
      </Helmet>
      <AberturasDescription />
      <AberturasPanel />
      <QuotePanel />
    </>
  );
};

export default Aberturas;
