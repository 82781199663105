import React from "react";

import FaqOption from "./FaqOption";

const questions = [
  {
    question: "¿Dónde están ubicados?",
    answer:
      "Nos encontramos en Montevideo, pero realizamos presupuestos en todo el país.",
  },
  {
    question: "¿Cuál es su número de contacto?",
    answer: "097 365 931",
  },
  {
    question: "¿Cuál es su horario de atención?",
    answer: "De lunes a viernes de 9 a 18 horas y sábados de 9 a 14 horas.",
  },
  {
    question: "¿Tiene costo la visita?",
    answer:
      "Las visitas dentro de Montevideo son sin costo asociado. También viajamos al interior del país con costo de traslado a cargo del cliente.",
  },
  {
    question: "¿Cuentan con local comercial?",
    answer:
      "Actualmente nuestra atención es totalmente en linea, no contamos con local comercial. Coordinamos visitas a domicilio para asesoramiento general y relevamiento. Las visitas dentro de Montevideo son sin costo.",
  },
  {
    question: "¿Emiten factura con RUT?",
    answer:
      "Si, somos una empresa debidamente registrada ante DGI y BPS y emitimos factura con RUT.",
  },
  {
    question: "¿Aceptan tarjetas de crédito?",
    answer:
      "Si. Para financiación en cuotas utilizamos Mercado Pago, que brinda hasta 12 cuotas sin recargo con VISA, Mastercard, OCA y creditel.",
  },
  {
    question: "¿Trabajan con entidades públicas?",
    answer:
      "Si. Somos una empresa registrada ante el RUPE, habilitada para trabajos y ventas a organismos estatales.",
  },
];

const FaqPanel = () => {
  return (
    <div className="faqs">
      <div className="container">
        <div className="section-header text-center">
          <h1 className="display-5 text-uppercase mb-4">
            Preguntas <span className="text-primary">Frecuentes</span>
          </h1>
        </div>
        <div className="row">
          {questions.map((q, index) => (
            <div className="col-md-6 mb-2">
              <div id="accordion-1">
                <div className="card wow fadeInRight" data-wow-delay="0.2s">
                  <FaqOption key={q} question={q.question} answer={q.answer} />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FaqPanel;
