import React from "react";
import Reciclado from "../detail/Reciclado";

const Reciclados = () => {
  return (
    <div className="col-lg-4 col-md-6">
      <div className="service-item bg-white rounded d-flex flex-column align-items-center text-center">
        <img
          className="img-fluid"
          src="img/servicios/reformas/reformas.jpeg"
          alt="reformas"
        />
        <div className="service-icon bg-white">
          <i className="fa fa-3x fa-tools text-primary"></i>
        </div>
        <div className="px-4 pb-4">
          <h4 className="text-uppercase mb-3">Remodelaciones y Reciclado</h4>
          <p>
            ¿Buscas remodelar espacios de vivienda u oficina? Realizamos
            ampliaciones, reformas, revestimiento de baños y cocinas, cambio de
            aberturas y mucho mas.
          </p>
          <a className="btn text-primary" href="servicios/reformas">
            Mas información <i className="bi bi-arrow-right"></i>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Reciclados;
