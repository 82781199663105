import React from 'react'

const Logo = () => {
  return (
    <div className="col-lg-3 col-md-12">
    <div className="top-bar">
    <div className="container">
      <div className="row align-items-center">
          <div className="logo">
            <a href="/">
              <img src="img/construcciones.png" alt="Logo" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}
export default Logo
