import React from 'react'
import MasInformación from '../../buttons/MasInformación'

const LlaveEnMano = () => {
  return (
    <div className="col-lg-4 col-md-6">
    <div className="service-item bg-white rounded d-flex flex-column align-items-center text-center">
        <img className="img-fluid" src="img/servicios/llave_en_mano/llave-en-mano.jpeg" alt=""/>
        <div className="service-icon bg-white">
      <i className="bi bi-key-fill h1 text-primary"></i>
        </div>
        <div className="px-4 pb-4">
            <h4 className="text-uppercase mb-3">Construcción LLave en mano</h4>
            <p>¿Contás con un terreno y planeas construir? Te ofrecemos una solución libre de trámites y gestiones. Contrato cerrado con entregas por etapas y sujeto resultados. </p>
            <MasInformación href={"servicios/llave-en-mano"}/>
        </div>
    </div>
</div>
  )
}

export default LlaveEnMano
