import React from 'react'

const Impermeabilizaciones = () => {
  return (
       <div className="col-lg-4 col-md-6">
                <div className="service-item bg-white rounded d-flex flex-column align-items-center text-center">
                    <img className="img-fluid" src="img/servicios/sanitaria/plomeria.jpg" alt=""/>
                    <div className="service-icon bg-white">
                    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor" className="bi bi-wrench-adjustable h1  text-primary" viewBox="0 0 16 16">
  <path d="M16 4.5a4.492 4.492 0 0 1-1.703 3.526L13 5l2.959-1.11c.027.2.041.403.041.61Z"/>
  <path d="M11.5 9c.653 0 1.273-.139 1.833-.39L12 5.5 11 3l3.826-1.53A4.5 4.5 0 0 0 7.29 6.092l-6.116 5.096a2.583 2.583 0 1 0 3.638 3.638L9.908 8.71A4.49 4.49 0 0 0 11.5 9Zm-1.292-4.361-.596.893.809-.27a.25.25 0 0 1 .287.377l-.596.893.809-.27.158.475-1.5.5a.25.25 0 0 1-.287-.376l.596-.893-.809.27a.25.25 0 0 1-.287-.377l.596-.893-.809.27-.158-.475 1.5-.5a.25.25 0 0 1 .287.376ZM3 14a1 1 0 1 1 0-2 1 1 0 0 1 0 2Z"/>
</svg>
                    </div>
                    <div className="px-4 pb-4">
                        <h4 className="text-uppercase mb-3">Sanitaria</h4>
                        <p> Presupuestos sin cargo y servicio de urgencias. Ajustes, reparaciones y cambio de grifería. Desobstrucciones, actualizaciones de cañerias y más</p>
                        <a className="btn text-primary" href="servicios/sanitaria">Mas información <i className="bi bi-arrow-right"></i></a>
                    </div>
                </div>
            </div>
  )
}

export default Impermeabilizaciones
