import React from "react";
import Get_quote from "../../buttons/Get_quote";
import SaberMas from "../../buttons/SaberMas";

const Carousel = () => {
  const styles = {
    maxWidth: "900px",
  };
  return (
    <div className="container-fluid p-0">
      <div
        id="header-carousel"
        className="carousel slide carousel-fade"
        data-bs-ride="carousel"
      >
        <div className="carousel-inner">
          <div className="carousel-item active">
            <img
              className="w-100"
              src="img/carousel/obras-reforma.jpeg"
              alt="Image"
            />
            <div className="carousel-caption d-flex flex-column align-items-center justify-content-center">
              <div className="p-3" style={styles}>
                <h1 className="display-2 text-uppercase text-white mb-md-4">
                  ¿Necesitas reformar tu casa?
                </h1>
                <p>
                  Te ofrecemos soluciones en reformas de pequeña y mediana
                  escala
                </p>
                <SaberMas href={"servicios/reformas"} />
              </div>
            </div>
          </div>
          <div className="carousel-item">
            <img
              className="w-100"
              src="img/carousel/humedad_3.jpeg"
              alt="Image"
            />
            <div className="carousel-caption d-flex flex-column align-items-center justify-content-center">
              <div className="p-3" style={styles}>
                <h1 className="display-2 text-uppercase text-white mb-md-4">
                  Solucionamos tus problemas de hùmedad y filtraciones
                </h1>
                <p>Impearmebilizamos techos, paredes y azoteas</p>
                <SaberMas href={"servicios/impermeabilizaciones"} />
              </div>
            </div>
          </div>
          <div className="carousel-item">
            <img
              className="w-100"
              src="img/carousel/contenedor_1.jpeg"
              alt="Image"
            />
            <div className="carousel-caption d-flex flex-column align-items-center justify-content-center">
              <div className="p-3" style={styles}>
                <h1 className="display-2 text-uppercase text-white mb-md-4">
                  Contenedores Prefabricados
                </h1>
                <p>
                  Una solución habitacional rápida y económica con planes a
                  medida{" "}
                </p>
                <SaberMas href={"servicios/contenedores"} />
              </div>
            </div>
          </div>
        </div>
        <button
          className="carousel-control-prev"
          type="button"
          data-bs-target="#header-carousel"
          data-bs-slide="prev"
        >
          <span
            className="carousel-control-prev-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button
          className="carousel-control-next"
          type="button"
          data-bs-target="#header-carousel"
          data-bs-slide="next"
        >
          <span
            className="carousel-control-next-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>
    </div>
  );
};

export default Carousel;
