import React from "react";
import NotFound from "../../../pages/NotFound";
import LlaveEnMano from "./LlaveEnMano";
import Electricidad from "./Electricidad";
import Sanitaria from "./Sanitaria";
import Reciclado from "./Reciclado";
import Impermeabilizaciones from "./Impermeabilizaciones";
import ContenedoresDetail from "./Contenedores";

const Detail = ({ param }) => {
  return (
    <>
      {(() => {
        switch (param) {
          case "llave-en-mano":
            return <LlaveEnMano />;
          case "electricidad":
            return <Electricidad />;
          case "sanitaria":
            return <Sanitaria />;
          case "reformas":
            return <Reciclado />;
          case "impermeabilizaciones":
            return <Impermeabilizaciones />;
          case "contenedores":
            return <ContenedoresDetail />;
          default:
            return <NotFound />;
        }
      })()}
    </>
  );
};

export default Detail;
