import React from "react";
import { Helmet } from "react-helmet";

const Impermeabilizaciones = () => {
  const style = {
    objectFit: {
      objectFit: "cover",
    },
    minHeight: {
      minHeight: "400px",
    },
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title> Impermeabilizaciones - construcción urucortinas</title>
        <link
          rel="canonical"
          href="https://construccion.urucortinas.com.uy/servicios/impermeabilizaciones"
        />
        <meta
          name="description"
          content="Impermeabilizaciones - construcción urucortinas | Montevideo | Uruguay | 097365931. Instalación de placas antihumedad. Membrana asfáltica. "
        />
      </Helmet>
      <div className="container-fluid py-6 px-5">
        <div className="row g-5">
          <div className="col-lg-5 pb-5" style={style.minHeight}>
            <div className="position-relative bg-dark-radial h-100 ms-5">
              <img
                className="position-absolute w-100 h-100 mt-5 ms-n5"
                src="img/servicios/impermeabilizaciones/impermeabilizacion-detail.jpg"
                style={style.objectFit}
              />
            </div>
          </div>
          <div className="col-lg-7">
            <h1 className="display-5 text-uppercase mb-4">
              Impermeabilizaciones
            </h1>
            <h4 className="text-uppercase mb-3 text-body">
              evite hùmedades y filtraciones con nuestro efectivo tratamiento
              antihúmedad
            </h4>
            <p>
              Tratamiento antihúmedad e impermeabilización de paredes, azoteas,
              mantenimiento de terrazas, colocación de membranas asfálticas
              según la superficie, el material,si son transitables o la
              exposición a temperaturas, entre otros factores. Membranas
              asfálticas Membranas de PVC Membranas líquidas Protección de
              impermeabilización Poliureas
            </p>
            <div className="row gx-5 py-2">
              <div className="col-sm-6 mb-2">
                <p className="fw-bold mb-2">
                  <i className="fa fa-check text-primary me-3"></i>Instalación
                  de Membranas asfálticas, membranas de PVC o membranas líquidas
                </p>
                <p className="fw-bold mb-2">
                  <i className="fa fa-check text-primary me-3"></i>Tratamientos
                  antihúmedad
                </p>
              </div>
              <div className="col-sm-6 mb-2">
                <p className="fw-bold mb-2">
                  <i className="fa fa-check text-primary me-3"></i>Mantenimiento
                  de azoteas y terrazas
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Impermeabilizaciones;
