import React, {lazy } from 'react'
import { Outlet } from 'react-router-dom'
import NavBar from '../components/navbar/NavBar'
import Footer from '../components/Footer'
import TopBar from '../components/navbar/TopBar'
import Whatsapp from '../components/Whatsapp'
// const Page404 = lazy(() => import('../pages/404'))

const Layout = () => {
  return (
    <>
         <Whatsapp/>
          <TopBar/>
          <NavBar/>
          <Outlet />
          <Footer/>
      </>
  )
}

export default Layout
