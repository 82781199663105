import React from "react";
import AberturasItem from "./AberturasItem";
const AberturasPanel = () => {
  const syle = {
    maxWidth: {
      maxWidth: "600px",
    },
    minHeight: {
      minHeight: "300px",
    },
    objectFit: {
      objectFit: "cover",
    },
  };

  const data = [
    {
      title: "Serie Gala",
      subTitle: "Alta prestación",
      img: "img/productos/aberturas/gala_main.png",
    },
    {
      title: "Serie Probba",
      subTitle: "Alta prestación",
      img: "img/productos/aberturas/probba_main.png",
    },
    {
      title: "Serie 20 y 25",
      subTitle: "Medidas estándar",
      img: "img/productos/aberturas/estandar_new.png",
    },
  ];

  return (
    <div className="container-fluid py-6 px-5">
      <div className="text-center mx-auto mb-5">
        <h1 className="display-5 text-uppercase mb-5">
          Te presentamos nuestra linea de
          <span className="text-primary">Aberturas</span>
        </h1>
      </div>
      <div className="row g-5">
        {data.map((item, index) => (
          <AberturasItem
            key={index}
            title={item.title}
            subTitle={item.subTitle}
            img={item.img}
          />
        ))}
      </div>
    </div>
  );
};

export default AberturasPanel;
