import React from "react";
import ServiceHeader from "../components/headers/ServiceHeader";
import ServicesPanel from "../components/ServicesPanel";
import { Helmet } from "react-helmet";

const Services = () => {
  return (
    <>
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Servicios - construcción urucortinas</title>
          <link
            rel="canonical"
            href="https://construccion.urucortinas.com.uy/servicios"
          />
          <meta
            name="description"
            content="Servicios - construcción urucortinas | Montevideo | Uruguay | 097365931 "
          />
        </Helmet>
      </>
      <ServiceHeader />
      <ServicesPanel />
    </>
  );
};

export default Services;
