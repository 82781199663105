import React from 'react'

const Get_quote = () => {
  return (
    <>
      <a className="btn btn-primary py-3 px-5" href="solicitar-presupuesto">Solicitá presupuesto</a>
    </>
  )
}

export default Get_quote
