import React from "react";

const ContactPanel = () => {
  const styles = {
    height: "55px",
  };
  return (
    <div className="container-fluid py-6 px-4">
      <div className="row gx-5">
        <div className="col-lg-4 mb-2 mb-lg-0">
          <div className="mb-4">
            <h1 className="display-5 text-uppercase mb-4">
              Conoce nuestras líneas de
              <span className="text-primary"> contacto</span>
            </h1>
          </div>
          <p className="mb-5">
            Contáctenos a través de nuestras redes sociales y puntos de contacto
            para solicitarnos presupuesto o aclarar tus dudas y responderemos de
            inmediato. Esperamos tu mensaje.
          </p>
        </div>
        <div className="col-lg-8">
          <div className="bg-light p-1">
            <div className="row">
              <div className="col-lg-6 py-3  d-lg-block">
                <div className="d-inline-flex align-items-center">
                  <a href="https://api.whatsapp.com/send?phone=59897365931&amp;text=Hola,%20te%20contacto%20desde%20la%20web%20de%20urucortinas:">
                    {" "}
                    <i className="bi bi-whatsapp fs-1 text-primary me-3"></i>
                  </a>
                  <div className="text-start">
                    <h6 className="text-uppercase fw-bold">WhatsApp</h6>
                    <span>097 365 931</span>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 border-start py-3  d-lg-block">
                <div className="d-inline-flex align-items-center">
                  <i className="bi bi-facebook fs-1 text-primary me-3"></i>
                  <div className="text-start">
                    <h6 className="text-uppercase fw-bold">Facebook</h6>
                    <span>www.facebook.com/urucortinas</span>
                  </div>
                </div>
              </div>
              <div className="col-lg-6  py-3   d-lg-block">
                <div className="d-inline-flex align-items-center">
                  <i className="bi bi-envelope-open fs-1 text-primary me-3"></i>
                  <div className="text-start">
                    <h6 className="text-uppercase fw-bold">
                      Correo Electrónico
                    </h6>
                    <span>construccion@urucortinas.com.uy</span>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 border-start py-3  d-lg-block">
                <div className="d-inline-flex align-items-center">
                  <i className="bi bi-instagram fs-1 text-primary me-3"></i>
                  <div className="text-start">
                    <h6 className="text-uppercase fw-bold">Instagram</h6>
                    <span>www.instagram.com/urucortinas_uy</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactPanel;
