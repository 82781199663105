import React from 'react'

const ProductListPanel = () => {
    const styles = {
        width:" 150px",
        height: "100px"
    };

    const stylesBackground = {
        background: "rgba(4, 15, 40, .3)"
    };

    const maxWidth = {
        maxWidth: "600px"
    };

  return (
    <div className="container-fluid bg-light py-6 px-5">
        <div className="text-center mx-auto mb-5" style={maxWidth}>
            <h1 className="display-5 text-uppercase mb-4">Lista de <span className="text-primary">Productos</span></h1>
        </div>
        <div className="row gx-5">
            <div className="col-12 text-center">
                <div className="d-inline-block bg-dark-radial text-center pt-4 px-5 mb-5">
                    <ul className="list-inline mb-0" id="portfolio-flters">
                        <li className="btn btn-outline-primary bg-white p-2 active mx-2 mb-4" data-filter="*">
                            <img src="img/portfolio-1.jpg" style={styles}/>
                            <div className="position-absolute top-0 start-0 end-0 bottom-0 m-2 d-flex align-items-center justify-content-center"  style={stylesBackground}>
                                <h6 className="text-white text-uppercase m-0">All</h6>
                            </div>
                        </li>
                        <li className="btn btn-outline-primary bg-white p-2 mx-2 mb-4" data-filter=".first">
                            <img src="img/portfolio-2.jpg" style={styles}/>
                            <div className="position-absolute top-0 start-0 end-0 bottom-0 m-2 d-flex align-items-center justify-content-center" style={stylesBackground}>
                                <h6 className="text-white text-uppercase m-0">Construction</h6>
                            </div>
                        </li>
                        <li className="btn btn-outline-primary bg-white p-2 mx-2 mb-4" data-filter=".second">
                            <img src="img/portfolio-3.jpg" style={styles}/>
                            <div className="position-absolute top-0 start-0 end-0 bottom-0 m-2 d-flex align-items-center justify-content-center"  style={stylesBackground}>
                                <h6 className="text-white text-uppercase m-0">Renovation</h6>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div className="row g-5 portfolio-container">
            <div className="col-xl-4 col-lg-6 col-md-6 portfolio-item first">
                <div className="position-relative portfolio-box">
                    <img className="img-fluid w-100" src="img/portfolio-1.jpg" alt=""/>
                    <a className="portfolio-title shadow-sm" href="">
                        <p className="h4 text-uppercase">Project Name</p>
                        <span className="text-body"><i className="fa fa-map-marker-alt text-primary me-2"></i>123 Street, New York, USA</span>
                    </a>
                    <a className="portfolio-btn" href="img/portfolio-1.jpg" data-lightbox="portfolio">
                        <i className="bi bi-plus text-white"></i>
                    </a>
                </div>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6 portfolio-item second">
                <div className="position-relative portfolio-box">
                    <img className="img-fluid w-100" src="img/portfolio-2.jpg" alt=""/>
                    <a className="portfolio-title shadow-sm" href="">
                        <p className="h4 text-uppercase">Project Name</p>
                        <span className="text-body"><i className="fa fa-map-marker-alt text-primary me-2"></i>123 Street, New York, USA</span>
                    </a>
                    <a className="portfolio-btn" href="img/portfolio-2.jpg" data-lightbox="portfolio">
                        <i className="bi bi-plus text-white"></i>
                    </a>
                </div>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6 portfolio-item first">
                <div className="position-relative portfolio-box">
                    <img className="img-fluid w-100" src="img/portfolio-3.jpg" alt=""/>
                    <a className="portfolio-title shadow-sm" href="">
                        <p className="h4 text-uppercase">Project Name</p>
                        <span className="text-body"><i className="fa fa-map-marker-alt text-primary me-2"></i>123 Street, New York, USA</span>
                    </a>
                    <a className="portfolio-btn" href="img/portfolio-3.jpg" data-lightbox="portfolio">
                        <i className="bi bi-plus text-white"></i>
                    </a>
                </div>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6 portfolio-item second">
                <div className="position-relative portfolio-box">
                    <img className="img-fluid w-100" src="img/portfolio-4.jpg" alt=""/>
                    <a className="portfolio-title shadow-sm" href="">
                        <p className="h4 text-uppercase">Project Name</p>
                        <span className="text-body"><i className="fa fa-map-marker-alt text-primary me-2"></i>123 Street, New York, USA</span>
                    </a>
                    <a className="portfolio-btn" href="img/portfolio-4.jpg" data-lightbox="portfolio">
                        <i className="bi bi-plus text-white"></i>
                    </a>
                </div>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6 portfolio-item first">
                <div className="position-relative portfolio-box">
                    <img className="img-fluid w-100" src="img/portfolio-5.jpg" alt=""/>
                    <a className="portfolio-title shadow-sm" href="">
                        <p className="h4 text-uppercase">Project Name</p>
                        <span className="text-body"><i className="fa fa-map-marker-alt text-primary me-2"></i>123 Street, New York, USA</span>
                    </a>
                    <a className="portfolio-btn" href="img/portfolio-5.jpg" data-lightbox="portfolio">
                        <i className="bi bi-plus text-white"></i>
                    </a>
                </div>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6 portfolio-item second">
                <div className="position-relative portfolio-box">
                    <img className="img-fluid w-100" src="img/portfolio-6.jpg" alt=""/>
                    <a className="portfolio-title shadow-sm" href="">
                        <p className="h4 text-uppercase">Project Name</p>
                        <span className="text-body"><i className="fa fa-map-marker-alt text-primary me-2"></i>123 Street, New York, USA</span>
                    </a>
                    <a className="portfolio-btn" href="img/portfolio-6.jpg" data-lightbox="portfolio">
                        <i className="bi bi-plus text-white"></i>
                    </a>
                </div>
            </div>
        </div>
    </div>
    
  )
}

export default ProductListPanel
