import React from "react";
import ProductsPanel from "../components/ProductsPanel";
import { Helmet } from "react-helmet";

const Products = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Productos - construcción urucortinas</title>
      </Helmet>

      <ProductsPanel />
    </>
  );
};

export default Products;
