import React from 'react'

const MasInformación = ({href}) => {
  return (
    <>
      <a className="btn text-primary" href={href}>Mas información <i className="bi bi-arrow-right"></i></a>
    </>
  )
}

export default MasInformación
