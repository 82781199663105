import React, { useState } from "react";
import Collapse from "react-bootstrap/Collapse";

const FaqOption = ({ question, answer }) => {
  const [open, setOpen] = useState(false);

  return (
    <div>
      <div className="card-header">
        <a
          className="card-link collapsed"
          data-toggle="collapse"
          onClick={() => setOpen(!open)}
          aria-controls="example-collapse-text"
          aria-expanded={open}
        >
          {question}
        </a>
      </div>
      <Collapse in={open}>
        <div className="collapse">
          <div className="card-body">{answer}</div>
        </div>
      </Collapse>
    </div>
  );
};

export default FaqOption;
