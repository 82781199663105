import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Get_quote from "../buttons/Get_quote";

function NavBar() {
  const sideBar = [
    {
      item: "Inicio",
      href: "/",
    },
    {
      item: "Servicios",
      routes: [
        {
          href: "servicios/llave-en-mano",
          name: "Construcciones llave en mano",
        },
        {
          href: "servicios/reformas",
          name: "Reciclado y Reformas",
        },
        {
          href: "servicios/contenedores",
          name: "Casas contenedores",
        },
        {
          href: "servicios/electricidad",
          name: "Instalaciones eléctricas",
        },
        {
          href: "servicios/impermeabilizaciones",
          name: "Impermeabilizaciones",
        },
        {
          href: "servicios/sanitaria",
          name: "Sanitaria",
        },
      ],
    },
    {
      item: "Productos",
      routes: [
        {
          href: "productos/aberturas",
          name: "Aberturas en Aluminio",
        },
        // {
        //   href: "productos/puertas-oblak",
        //   name: "Puertas Oblak",
        // },
      ],
    },
    {
      item: "Contacto",
      href: "/contacto",
    },
  ];
  return (
    <Navbar
      sticky="top"
      bg="light"
      expand="lg"
      className="navbar navbar-expand-lg bg-dark bg-light-radial navbar-dark py-3 py-lg-0"
    >
      <Container>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            {sideBar.map((route, index) =>
              route.routes ? (
                <NavDropdown title={route.item} id={index} key={index}>
                  {route.routes.map((item, i) => (
                    <NavDropdown.Item href={item.href} key={i}>
                      {item.name}
                    </NavDropdown.Item>
                  ))}
                </NavDropdown>
              ) : (
                <Nav.Link href={route.href} key={index}>
                  {route.item}
                </Nav.Link>
              )
            )}
          </Nav>
        </Navbar.Collapse>
        <Get_quote />
      </Container>
    </Navbar>
  );
}
export default NavBar;
