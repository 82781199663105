import React, { useState, useEffect } from "react";

const ServiceHeader = ({ param }) => {
  return (
    <>
      <div className="container-fluid page-header">
        <h1 className="display-3 text-uppercase text-white mb-3">Servicios</h1>
        <div className="d-inline-flex text-white">
          <h6 className="text-uppercase m-0">
            <a href="/">Inicio</a>
          </h6>
          <h6 className="text-white m-0 px-3">/</h6>
          <h6 className="text-uppercase m-0">
            <a href="/servicios">Servicios</a>
          </h6>
          <h6 className="text-white m-0 px-3">/</h6>
          <h6 className="text-uppercase text-white m-0">{loadTitle(param)}</h6>
        </div>
      </div>
    </>
  );

  function loadTitle(param) {
    switch (param) {
      case "llave-en-mano":
        return "Llave en mano";
      case "electricidad":
        return "Electricidad";
      case "sanitaria":
        return "Sanitaria";
      case "reformas":
        return "Reciclado y reformas";
      case "impermeabilizaciones":
        return "Impermeabilizaciones";
      case "contenedores":
        return "Contenedores";
    }
  }
};

export default ServiceHeader;
