import React from "react";
import LlaveEnMano from "./services/preview/LlaveEnMano";
import Electricidad from "./services/preview/Electricidad";
import Impermeabilizaciones from "./services/preview/Impermeabilizaciones";
import Reciclados from "./services/preview/Reciclados";
import Sanitaria from "./services/preview/Sanitaria";
import Contenedores from "./services/preview/Contenedores";

const ServicesPanel = () => {
  const styles = {
    maxWidth: "600px",
  };
  return (
    <>
      <div className="text-center mx-auto mb-5" style={styles}>
        <h1 className="display-5 text-uppercase mb-4">
          Conocé algunos de nuestros{" "}
          <span className="text-primary"> servicios destacados</span>{" "}
        </h1>
      </div>
      <div className="row g-5">
        <LlaveEnMano />
        <Reciclados />
        <Contenedores />
        <Electricidad />
        <Impermeabilizaciones />
        <Sanitaria />
      </div>
    </>
  );
};

export default ServicesPanel;
