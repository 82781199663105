import React from "react";
import { Helmet } from "react-helmet";

const Electricidad = () => {
  const style = {
    objectFit: {
      objectFit: "cover",
    },
    minHeight: {
      minHeight: "400px",
    },
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Instalaciones eléctricas - construcción urucortinas</title>
        <link
          rel="canonical"
          href="https://construccion.urucortinas.com.uy/servicios/electricidad"
        />
        <meta
          name="description"
          content="Instalaciones eléctricas construcción urucortinas "
        />
      </Helmet>
      <div className="container-fluid py-6 px-5">
        <div className="row g-5">
          <div className="col-lg-5 pb-5" style={style.minHeight}>
            <div className="position-relative bg-dark-radial h-100 ms-5">
              <img
                className="position-absolute w-100 h-100 mt-5 ms-n5"
                src="img/servicios/electricidad/electricidad_detail.jpeg"
                style={style.objectFit}
              />
            </div>
          </div>
          <div className="col-lg-7">
            <h1 className="display-5 text-uppercase mb-4">
              Instalaciones <span className="text-primary">eléctricas</span>
            </h1>
            <h4 className="text-uppercase mb-3 text-body">
              Somos firma instaladora autorizada por UTE y contamos con personal
              certificado.
            </h4>
            <p>
              Instalación, mantenimiento y actualizaciones de lineas eléctricas.
              Desarrollamos proyectos de iluminación adaptados a las necesidades
              de cada cliente. Contamos con técnicos instaladores certificados y
              servicio de emergencia por cortes en el suministro de energía
              eléctrica, total o parcial, para locales comerciales, casas y
              edificios dentro de Montevideo.
            </p>
            <div className="row gx-5 py-2">
              <div className="col-sm-6 mb-2">
                <p className="fw-bold mb-2">
                  <i className="fa fa-check text-primary me-3"></i>Emergencia
                  por corte de energia
                </p>
                <p className="fw-bold mb-2">
                  <i className="fa fa-check text-primary me-3"></i>Actualización
                  de lineas eléctricas
                </p>
                <p className="fw-bold mb-2">
                  <i className="fa fa-check text-primary me-3"></i>Certificación
                  de instalaciones
                </p>
              </div>
              <div className="col-sm-6 mb-2">
                <p className="fw-bold mb-2">
                  <i className="fa fa-check text-primary me-3"></i>Mantenimiento
                  de locales y edificios.
                </p>
                <p className="fw-bold mb-2">
                  <i className="fa fa-check text-primary me-3"></i>Trámites ante
                  UTE.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Electricidad;
