import React from "react";

const Electricidad = () => {
  return (
    <div className="col-lg-4 col-md-6">
      <div className="service-item bg-white rounded d-flex flex-column align-items-center text-center">
        <img
          className="img-fluid"
          src="img/servicios/electricidad/instalacion-electrica.jpg"
          alt=""
        />
        <div className="service-icon bg-white">
          <i className="bi bi-lightning-fill h1 text-primary"></i>
        </div>
        <div className="px-4 pb-4">
          <h4 className="text-uppercase mb-3">Electricidad</h4>
          <p>
            Instalación, mantenimiento y actualizaciones de lineas eléctricas.
            Somos firma instaladora autorizada por UTE. Contamos con técnicos
            instaladores y servicio de emergencia dentro de Montevideo.
          </p>
          <a className="btn text-primary" href="servicios/electricidad">
            Mas información <i className="bi bi-arrow-right"></i>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Electricidad;
