import React from "react";
import { Helmet } from "react-helmet";

const LlaveEnMano = () => {
  const style = {
    objectFit: {
      objectFit: "cover",
    },
    minHeight: {
      minHeight: "400px",
    },
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Llave en mano - construcción urucortinas</title>
        <link
          rel="canonical"
          href="https://construccion.urucortinas.com.uy/servicios/llave-en-mano"
        />
        <meta name="description" content="construcción urucortinas home" />
      </Helmet>

      <div className="container-fluid py-6 px-5">
        <div className="row g-5">
          <div className="col-lg-5 pb-5" style={style.minHeight}>
            <div className="position-relative bg-dark-radial h-100 ms-5">
              <img
                className="position-absolute w-100 h-100 mt-5 ms-n5"
                src="img/llave-mano.jpg"
                style={style.objectFit}
              />
            </div>
          </div>
          <div className="col-lg-7">
            <h1 className="display-5 text-uppercase mb-4">
              Construcción <span className="text-primary">Llave en mano</span>
            </h1>
            <h4 className="text-uppercase mb-3 text-body">
              Contrato con precio cerrado y entregas por etapas terminadas
            </h4>
            <p>
              Nuestro contrato llave en mano le ofrece la posibilidad de un
              precio global cerrado y en plazos determinados. Nuestra amplia
              experiencia nos permite encargarnos de todos los trámites
              necesarios para la obra: desde permisos de construcción,
              habilitaciones de BPS, DGI, contratacion de personal y más. Las
              entregas económicas se realizan al finalizar etapas previa
              revisión con nuestro equipo. Nuestros diseños fueron creados por
              arquitectos y están listos para construir.
            </p>
            <div className="row gx-5 py-2">
              <h6 className="text-uppercase fw-bold">Incluye</h6>
              <div className="col-sm-6 mb-2">
                <p className="fw-bold mb-2">
                  <i className="fa fa-check text-primary me-3"></i>Diseño y
                  firma de arquitecto
                </p>
                <p className="fw-bold mb-2">
                  <i className="fa fa-check text-primary me-3"></i>Gestión de
                  permisos y cierre de obra ante intendencia
                </p>
                <p className="fw-bold mb-2">
                  <i className="fa fa-check text-primary me-3"></i>Trámites ante
                  BPS
                </p>
              </div>
              <div className="col-sm-6 mb-2">
                <p className="fw-bold mb-2">
                  <i className="fa fa-check text-primary me-3"></i>Materiales
                </p>
                <p className="fw-bold mb-2">
                  <i className="fa fa-check text-primary me-3"></i>Mano de obra
                </p>

                <p className="fw-bold mb-2">
                  <i className="fa fa-check text-primary me-3"></i>Aberturas en
                  aluminio. Perfileria Aluminios del Uruguay, serie Probba
                </p>
              </div>
            </div>
            <div className="row gx-5 py-2">
              <h6 className="text-uppercase fw-bold">No incluye</h6>
              <div className="col-sm-6 mb-2">
                <p className="fw-bold mb-2">
                  <i className="fa  fa-times text-primary me-3"></i>Pozo negro
                </p>
                <p className="fw-bold mb-2">
                  <i className="fa fa-times text-primary me-3"></i>
                  Nivelación del terreno
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LlaveEnMano;
