import React from "react";
import ContactPanel from "../components/ContactPanel";
import FaqPanel from "../components/faq/FaqPanel";
import { Helmet } from "react-helmet";

const Contact = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          content="Conoce nuestros puntos de contacto Contacto | urucortinas construcción | Montevideo | Uruguay | 097365931 "
          name="description"
        />
        <title> Contacto - construcción urucortinas</title>
        <link
          rel="canonical"
          href="https://construccion.urucortinas.com.uy/contacto"
        />
      </Helmet>

      <FaqPanel />
      <ContactPanel />
    </>
  );
};

export default Contact;
