import React from "react";
import Quote from "../components/QuotePanel";
import Carousel from "../components/sections/carousel/Carousel";
import Products from "../components/ProductsPanel";
import Services from "../components/ServicesPanel";
import { Helmet } from "react-helmet";

const Home = () => {
  return (
    <>
      <Helmet>
        <meta
          content="Empresa del rubro de la construcción y servicios afines. Ofrece soluciones y productos necesarios para el desarrollo de actividades relacionadas a la construcción en Montevideo y todo Uruguay."
          name="description"
        />
        <title>Incio - construcción urucortinas</title>
        <link rel="canonical" href="https://construccion.urucortinas.com.uy" />
      </Helmet>
      <Carousel />
      <div className="container-fluid bg-light py-6 px-3">
        <Services />
        <Products />
        <Quote />
      </div>
      {/* <Team/> */}
      {/* <Testimonials/> */}
      {/* <Blog/> */}
    </>
  );
};

export default Home;
