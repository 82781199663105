import React from "react";
import Aberturas from "./products/preview/Aberturas";
import PuertasOblak from "./products/preview/PuertasOblak";

const ProductsPanel = () => {
  const styles = {
    maxWidth: "600px",
  };
  return (
    <>
      <div className="text-center mx-auto mb-5" style={styles}>
        <h1 className="display-5 text-uppercase mb-4">
          Conocé tambien nuestros{" "}
          <span className="text-primary">productos </span>{" "}
        </h1>
      </div>
      <div className="row g-5">
        <Aberturas />
        {/* <PuertasOblak/> */}
      </div>
    </>
  );
};

export default ProductsPanel;
