import React from "react";
import { Helmet } from "react-helmet";

const Reciclado = () => {
  const style = {
    objectFit: {
      objectFit: "cover",
    },
    minHeight: {
      minHeight: "400px",
    },
  };

  return (
    <>
      {" "}
      <Helmet>
        <meta charSet="utf-8" />
        <title> Reciclado y reformas - construcción urucortinas</title>
        <link
          rel="canonical"
          href="https://construccion.urucortinas.com.uy/servicios/reformas"
        />
        <meta name="description" content="construcción urucortinas home" />
      </Helmet>
      <div className="container-fluid py-6 px-5">
        <div className="row g-5">
          <div className="col-lg-5 pb-5" style={style.minHeight}>
            <div className="position-relative bg-dark-radial h-100 ms-5">
              <img
                className="position-absolute w-100 h-100 mt-5 ms-n5"
                src="img/servicios/reformas/reformas_detail.jpeg"
                style={style.objectFit}
              />
            </div>
          </div>
          <div className="col-lg-7">
            <h1 className="display-5 text-uppercase mb-4">
              Reciclado y reformas
            </h1>
            <h4 className="text-uppercase mb-3 text-body">
              Te ofrecemos planes de reformas integrales para proyectos de
              inversión o vivienda.
            </h4>
            <p>
              Prestamos servicios de reformas, reparaciones y mantenimiento en
              general de viviendas, locales comerciales, oficinas, adaptando sus
              espacios, materiales y estructuras para hacerlo mas cómoda,
              habitable y funcional. Se esta forma logramos el objetivo de que
              su inversión mantenga su valor en el tiempo. También nos
              encargamos de
            </p>
            <div className="row gx-5 py-2">
              <div className="col-sm-6 mb-2">
                <p className="fw-bold mb-2">
                  <i className="fa fa-check text-primary me-3"></i>Reformas y
                  mantenimientos
                </p>
                <p className="fw-bold mb-2">
                  <i className="fa fa-check text-primary me-3"></i>Revestimiento
                  de baños y cocinas
                </p>
                <p className="fw-bold mb-2">
                  <i className="fa fa-check text-primary me-3"></i>Cambio de
                  aberturas
                </p>
              </div>
              <div className="col-sm-6 mb-2">
                <p className="fw-bold mb-2">
                  <i className="fa fa-check text-primary me-3"></i>Actualización
                  de lineas eléctricas
                </p>
                <p className="fw-bold mb-2">
                  <i className="fa fa-check text-primary me-3"></i>Actualización
                  de sanitaria
                </p>
                <p className="fw-bold mb-2">
                  <i className="fa fa-check text-primary me-3"></i>Trabajos de
                  pintura
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Reciclado;
