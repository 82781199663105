import React from "react";

const Footer = () => {
  const syle = {
    padding: "20px 30px",
  };

  return (
    <>
      <div className="footer container-fluid position-relative bg-dark bg-light-radial text-white-50 py-6 px-5">
        <div className="row g-5">
          <div className="col-lg-6 pe-lg-5">
            <h4 className="text-white text-uppercase mb-4">
              Urucortinas construcción
            </h4>
            <p>
              Ampliamos nuestros productos y servicios para brindarte soluciones
              integrales para el hogar y construcción.
            </p>
            <p>
              <i className="fa fa-map-marker-alt me-2"></i>Montevideo, Uruguay
            </p>
            <p>
              <i className="fa fa-phone-alt me-2"></i>097 365 931
            </p>
            <p>
              <i className="fa fa-envelope me-2"></i>
              construccion@urucortinas.com.uy
            </p>
            <div className="d-flex justify-content-start mt-4">
              <a
                className="btn btn-lg btn-primary btn-lg-square rounded-0 me-2"
                href="https://www.facebook.com/urucortinas"
              >
                <i className="fab fa-facebook-f"></i>
              </a>
              <a
                className="btn btn-lg btn-primary btn-lg-square rounded-0"
                href="https://www.instagram.com/urucortinas_uy"
              >
                <i className="fab fa-instagram"></i>
              </a>
            </div>
          </div>
          <div className="col-lg-6 ps-lg-5">
            <div className="row g-5">
              <div className="col-sm-6">
                <h4 className="text-white text-uppercase mb-4">
                  Enlaces de interés
                </h4>
                <div className="d-flex flex-column justify-content-start">
                  <a className="text-white-50 mb-2" href="/">
                    <i className="fa fa-angle-right me-2"></i>Inicio
                  </a>
                  <a className="text-white-50 mb-2" href="/servicios">
                    <i className="fa fa-angle-right me-2"></i>Servicios
                  </a>
                  <a className="text-white-50 mb-2" href="/productos">
                    <i className="fa fa-angle-right me-2"></i>Productos
                  </a>
                  <a className="text-white-50" href="/contacto">
                    <i className="fa fa-angle-right me-2"></i>Contacto
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid bg-dark bg-light-radial text-white border-top border-primary px-0">
        <div className="d-flex flex-column flex-md-row justify-content-between">
          <div className="py-4 px-5 text-center text-md-start">
            <p className="mb-0">
              &copy;{" "}
              <a className="text-primary" href="#">
                urucortinas-construcción
              </a>
              . All Rights Reserved.
            </p>
          </div>
          <div className="py-4 px-5 bg-primary footer-shape position-relative text-center text-md-end"></div>
        </div>
      </div>
    </>
  );
};

export default Footer;
